import gql from 'graphql-tag';

const GET_FRAGMENT = gql`
  query pageFragment($id: ID) {
    fragmentPayload(id: $id)
      @rest(type: "FragmentPayload", path: "/:id", endpoint: "fragment") {
      fragment @type(name: "FragmentPayloadData") {
        id
        props @type(name: "Props") @edit(as: "Panel") {
          widget @edit(as: "Code", label: "Код виджета", docs: "https://nethouse.ru/about/instructions/kak_dobavit_vidzhet_poiskovye_formy_travelpayouts")
          backgroundColor
            @edit(as: "Color", label: "Цвет фона", value: "#00A7E1")
        }
      }
    }
  }
`;

export default GET_FRAGMENT;
