const formattedWidget = widget => {
  function replacer(str, p1, p2) {
    let result = str;
    const isNumber = p2.match(/\d$/);
    if (isNumber) {
      result = p1 + '"' + p2 + '._nethouse"';
    }
    const isString = p2.match(/(\S+)"$/);
    if (isString) {
      result = p1 + isString[1] + '_nethouse"';
    }
    return result;
  }
  return widget.replace(/("marker":\s*)(\d+|"\S+")/g, replacer);
};

export { formattedWidget };
